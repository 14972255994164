import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Card from '../components/Card';
import OsmMap from '../components/OsmMap';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Soutenir l'association</h2>
          <p>Les dons sont déductible d'impots.</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <section>
            <h3 className="major">Pourquoi nous soutenir ?</h3>
            <br/>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
            <button className="default">Faire un don</button>
          </section>
          
          <br/>
          <br/>
          <br/>
          <br/>

          <section>
            <h3 className="major">Devenir Bénévole ?</h3>
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
        

            <button className="default">S'inscrire</button>
          </section>
          <br/>
          <br/>
          <br/>
          <br/>

          <h3 className="major">Ils nous soutiennent</h3>
          <section className="features">

            <Card type='donater' path="" name="Jeanne dupont" state="time"  image="0" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='donater' path="" name="Jeanne dupont" state="time"  image="0" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='donater' path="" name="Jeanne dupont" state="money"  image="0" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='donater' path="" name="Jeanne dupont" state="time"  image="0" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            <Card type='donater' path="" name="Jeanne dupont" state="money"  image="0" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet."/>
            

          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
